// https://lesmillsinternational.atlassian.net/browse/LA-2330
export const getChargifySecurityToken = `
  query getChargifySecurityToken($countryCode: String) {
    getChargifySecurityToken(countryCode: $countryCode) {
      securityToken
      subdomain
      publicKey
    }
  }
`
