// @flow
import * as React from "react"

import { Button } from "@lesmills/gatsby-theme-common"
import AccountManagementModal from "../AccountManagementModal"

type Props = {|
  title: string,
  subtitle?: React.Node,
  children?: React.Node,
  btnRejectLabel?: string,
  btnSubmitLabel?: string,
  handleReject?: () => void | Promise<void>,
  handleSubmit?: () => void | Promise<void>,
  handleDismiss: () => void | Promise<void>,
  isDisabledBtns?: Boolean,
|}

const Popup = ({
  title,
  subtitle,
  children,
  btnRejectLabel = "",
  btnSubmitLabel = "",
  handleReject = () => handleDismiss(),
  handleSubmit = () => {},
  handleDismiss,
  isDisabledBtns = false,
}: Props) => {
  return (
    <AccountManagementModal
      handleDismiss={handleDismiss}
      title={title}
      classNames={{
        wrapper: "z-50 w-full md:w-446 lg:w-590 mt-30 lg:mx-150",
        content:
          " animation-fade-in w-100vw md:w-fit-content md:w-moz-fit-content",
      }}
    >
      <div
        className={
          "font-base-light text-gray-800 leading-snug md:leading-7none md:text-2lg text-base text-center mb-20 text-3xs mt-60 md:mt-12"
        }
      >
        {subtitle}
      </div>
      {children}
      <div className="flex md:justify-around flex-wrap">
        <Button
          handleOnClick={() => handleSubmit()}
          disabled={isDisabledBtns}
          submitting={isDisabledBtns}
          className="btn btn-primary uppercase w-full py-3 md:w-48/100 mb-20 md:mb-0"
        >
          {btnSubmitLabel}
        </Button>
        {btnRejectLabel ? (
          <Button
            handleOnClick={() => handleReject()}
            disabled={isDisabledBtns}
            className="btn btn-layout w-full md:w-48/100 md:mb-0 mb-30"
          >
            {btnRejectLabel}
          </Button>
        ) : null}
      </div>
    </AccountManagementModal>
  )
}

export default Popup
