export const changeSubscriptionChargifyJs = `
  mutation changeSubscriptionChargifyJs($product_handle: String!) {
    changeSubscriptionChargifyJs(product_handle: $product_handle) {
      user_id
      product_handle
      product {
        product_handle
        name
        description
      }
    }
  }
`
