import {
  SENTRY_ACTIONS,
  SUBSCRIPTION_STATES,
  getSessionStorage,
  CURRENT_COUNTRY,
  captureException,
  client,
} from "@lesmills/gatsby-theme-common"

import { AXII_CONFIGS } from "../../../../src/configs/axii-config"
import { API, graphqlOperation } from "aws-amplify"
import { getChargifySecurityToken } from "../graphql/getChargifySecurityToken"

export const getSecurityChargifyToken = async (
  setIsLoading,
  setErrors,
  handleResponse,
  isRestartWhenMovingCountry = false,
  country = ""
) => {
  setIsLoading(true)
  client()
  try {
    const response = await API.graphql(
      graphqlOperation(getChargifySecurityToken, {
        ...(isRestartWhenMovingCountry && {
          countryCode: country,
        }),
      })
    )
    if (response.errors && response.errors.length > 0) {
      setIsLoading(false)
      setErrors({
        payment: response.errors[0].message,
      })
      captureException({
        action: SENTRY_ACTIONS.GET_SERCURITY_CHARGIFY_TOKEN,
        ...response.errors[0],
      })
    } else {
      handleResponse(response.data.getChargifySecurityToken)
      setIsLoading(false)
    }
  } catch (err) {
    captureException({
      action: SENTRY_ACTIONS.GET_SERCURITY_CHARGIFY_TOKEN,
      ...err,
    })
  }
}

export const enabled3DSBillingAddress = addressCountry =>
  AXII_CONFIGS.enabled3DSCountries.includes(addressCountry.toLowerCase()) ===
  true
// JIRA ticket: https://lesmillsinternational.atlassian.net/browse/LA-1427
// Check user country has include in list countries doesn't accept Paypal
export const isDisablePayPalCountries = country =>
  AXII_CONFIGS.disabledPayPalCountries.includes(
    country
      ? country.toLowerCase()
      : getSessionStorage(CURRENT_COUNTRY).toLowerCase()
  )

export const isFailedSubscription = (subscription = {}) =>
  subscription.state === SUBSCRIPTION_STATES.FAILED

// This function for get payment method of Braintree user OR chargify payment profile of Chargify user
// JIRA ticket: https://lesmillsinternational.atlassian.net/browse/LA-1652
export const getPaymentInfo = (userData = {}) => {
  const { chargifyPaymentProfile, paymentMethod } = userData

  // Priority get chargifyPaymentProfile(ChargifyJS)
  // If without chargifyPaymentProfile or chargifyPaymentProfile have no info email PP and cardType CC -> return paymentMethod(Braintree)
  if (
    !chargifyPaymentProfile ||
    (!chargifyPaymentProfile.email && !chargifyPaymentProfile.cardType)
  ) {
    return paymentMethod
  } else {
    return chargifyPaymentProfile
  }
}

export const isBraintreeUser = (userData = {}) => {
  const { paymentMethod, chargifyPaymentProfile } = userData
  return (
    paymentMethod &&
    (!chargifyPaymentProfile ||
      (!chargifyPaymentProfile.email && !chargifyPaymentProfile.last4))
  )
}
